:root {
  --clr-modal-xl-width: 65rem;
}

.login-wrapper-logo {
  right: 40px !important;
  top: 40px !important;
  width: 320px !important;
}

.logo {
  max-width: 90% !important;
}
